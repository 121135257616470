import isMobile from 'ismobilejs';

export default function (opts = {}) {

  opts = Object.assign({
    from: 'top',
    mobile: true,
    factor: 0.5
  }, opts);

  return {
    shown: false,
    overlayOpacity: 0,

    init() {
      if(isMobile(window.navigator).any && !opts.mobile) return;

      window.addEventListener('scroll', () => {
        this.onScroll();
      });
    },

    onScroll() {
      let fromTop = opts.from == 'top';

      let doChange = fromTop
        ? window.scrollY <= this.$el.clientHeight
        : window.scrollY >= (this.$el.offsetTop - window.outerHeight);

      let translateY = fromTop
        ? window.scrollY / this.$el.clientHeight
        : (window.scrollY - this.$el.offsetTop) / this.$el.clientHeight;
      translateY = translateY * opts.factor;

      if(doChange) {
        this.$el.style.transform = `translateY(${translateY * 100}%)`;
        this.overlayOpacity = window.scrollY / this.$el.offsetHeight;
      }
    },
  }
}
