export default function(type) {
  return {

    active: false,
    mouseX: null,
    mouseY: null,
    buttonHover: false,

    init() {
      let target = this.$refs.target;

      let button = this.$el.querySelector('button');

      target.onmouseenter = (event) => {
        this.active = true;
      };

      target.onmousemove = (event) => {
        this.reposition(event);
      };

      button.onmousemove = () => {
        this.buttonHover = true;
      };

      button.onmouseleave = () => {
        this.buttonHover = false;
      };

      this.$el.onmouseleave = () => {
        this.active = false;
      };

      window.addEventListener('scroll', () => {
        this.reposition();
      });
    },

    reposition(event) {
      if(!this.$refs.cursor) return;

      let cursorX, cursorY;
      this.mouseX = event ? event.clientX : this.mouseX;
      this.mouseY = event ? event.clientY : this.mouseY;
      cursorX = `${this.mouseX + window.scrollX + 10}px`;
      cursorY = `${this.mouseY + window.scrollY - 5}px`;
      this.$refs.cursor.style.left = cursorX;
      this.$refs.cursor.style.top = cursorY;
    }
  };
}
