/**
 * Usage:
 *
 * Step 1: Somewhere in JS...
 *   import Alpine from "alpinejs";
 *   import spine from "./headroom";
 *   Alpine.data('headroom', headroom);
 *   Alpine.start();
 *
 * Step 2: Somewhere in markup...
 *   <div x-data="headroom()" />
 *
 * @param selector
 * @returns {{init(): void, el: null, selector: null}}
 */

import Headroom from "headroom.js";

export default () => ({
  headroom: null,

  init() {
    this.headroom = new Headroom(this.$el);
    this.headroom.init();
  },
  state(disable) {
    if (this.headroom) {
      if (disable) {
        this.headroom.freeze();
      } else {
        this.headroom.unfreeze();
      }
    }
  }
});
