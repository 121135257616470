/**
 * Usage:
 *
 * Step 1: Somewhere in JS...
 *   import Alpine from "alpinejs";
 *   import spine from "./freeform";
 *   Alpine.data('freeform', freeform);
 *   Alpine.start();
 *
 * Step 2: Somewhere in markup...
 *   <div x-data="freeform()" />
 *
 * @param selector
 * @returns {{init(): void, el: null, selector: null}}
 */


export default () => ({

  init() {
    let form = this.$el.querySelector('form');
    if (form) {
      const param = '/dynamic?form=' + form.id;
      fetch(param).then(function(response) {
        // The API call was successful!
        if (response.ok) {
          return response.json();
        }
        // There was an error
        return Promise.reject(response);
      }).then(function(data) {
        // This is the JSON from our response
        form.querySelector('input[name=formHash]').value = data.hash;
        // Locate and update the CSRF input
        var csrf = data.csrf;
        form.querySelector('input[name=' + csrf.name + ']').value = csrf.value;

      }).catch(function(err) {
        // There was an error
        console.warn('Something went wrong.', err);
      });
    }
  }
});
