import Alpine from 'alpinejs';

import smoothScroll from '@js/parts/alpine/smoothScroll';
import freeform from '@js/parts/alpine/freeform';
import swiper from '@js/parts/alpine/swiper';
import { video, videoToggle } from '@js/parts/alpine/video';
import { a11yToggle } from '@js/parts/alpine/a11y';
import headroom from '@js/parts/alpine/headroom';
import observer from '@js/parts/alpine/observer';
import parallax from '@js/parts/alpine/parallax';
import cursorTracker from '@js/parts/alpine/cursorTracker';

import collapse from '@alpinejs/collapse';
import intersect from '@alpinejs/intersect';
import focus from '@alpinejs/focus';
import persist from '@alpinejs/persist';


// import $ from 'jquery';



// 1st party plugins
Alpine.plugin(collapse);
Alpine.plugin(intersect);
Alpine.plugin(focus);
Alpine.plugin(persist);

// Components
Alpine.data("smoothScroll", smoothScroll);
Alpine.data("headroom", headroom);
Alpine.data("freeform", freeform);
Alpine.data('swiper', swiper);
Alpine.data("video", video);
Alpine.data("videoToggle", videoToggle);
Alpine.data("a11yToggle", a11yToggle);
Alpine.data("observer", observer);
Alpine.data("parallax", parallax);
Alpine.data("cursorTracker", cursorTracker);

window.Alpine = Alpine;
Alpine.start();


// console.log(Alpine);
// $(document).ready(function() {

//   $('.js-hover-complicated').hover(
//     function() {
//         if ($( window ).width() > 991) {
//             $( this ).find('.hover-overlay').fadeIn();
//             $( this ).find('.inset').fadeOut();
//         }
//     }, function() {
//         if ($( window ).width() > 991) {
//           $( this ).find('.hover-overlay').fadeOut();
//           $( this ).find('.inset').fadeIn();
//         }
//     }
//   );

//   function matchHeight() {
//     for (var num = 1; num <= 5; num++) {
//       if ($('.match-height-'+num).length > 0) {
//         $('.match-height-'+num).height('auto');
//         var maxHeight = 0;
//         $('.match-height-'+num).each(function() {
//           if ($(this).height() > maxHeight) {
//             maxHeight = $(this).height();
//           }
//         });
//         $('.match-height-'+num).height(maxHeight);
//       }
//     }
//   }
//   matchHeight();
//   $(window).on('resize', function() {
//     matchHeight();
//   });

// });
