import 'slick-carousel';
import $ from 'jquery';

$(document).ready(function(){
  if($('.slick-wrapper').length){
    $('.slick-wrapper').slick({
      dots: false,
      infinite: true,
      speed: 300,
      slidesToShow: 1,
      centerMode: true,
      variableWidth: true,
      autoplay : true,
      arrows : false
    });
  }
});
