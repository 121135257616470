/**
 * Usage:
 *
 * Step 1: Somewhere in JS...
 *   import Alpine from "alpinejs";
 *   import headroom from "./video";
 *   Alpine.data('video', video);
 *   Alpine.start();
 *
 * Step 2: Somewhere in markup...
 *   <div x-data="video()" />
 *
 * @param selector
 * @returns {{init(): void, el: null, selector: null}}
 */
import Player from '@vimeo/player';

function isInViewport(el) {
  const rect = el.getBoundingClientRect();
  return (
    rect.top + rect.height > 0 &&
    rect.top <= (window.innerHeight || document.documentElement.clientHeight)
  );
}

export function video(src = '', vimeoOptions = {}, extraOptions = {}) {
  return {
    src: src,
    iframeCont: null,
    isPlaying: false,
    isPlayingOrig: false,
    isPaused: null,
    width: 1600,
    height: 900,

    get ratio() {
      return this.height / this.width;
    },

    get inverseRatio() {
      return this.width / this.height;
    },

    resize() {
      const w = this.$root.offsetWidth;
      const h = this.$root.offsetHeight;
      const containerRatio = h / w;

      if (containerRatio > this.ratio) {
        this.iframeCont.style.width = `${h * (this.inverseRatio)}px`;
        this.iframeCont.style.height = `${h}px`;
      } else {
        this.iframeCont.style.width = `${w}px`;
        this.iframeCont.style.height = `${w * (this.ratio)}px`;
      }
    },

    init() {

      this.$nextTick(() => {
        var opts = {
          url: src,
          height: this.$el.clientHeight,
          width: this.$el.clientWidth,

          allowfullscreen: 0,
          autopause: 0,
          autoplay: 0,
          controls: extraOptions.hasControls ? 1 : 0,
          loop: 1,
          muted: 1,
          playsinline: 1,
          responsive: 0,
          quality: 'auto',

          ...vimeoOptions,
        };

        if (!this.iframeCont) {
          var play = new Player(this.$el, opts);
          this.iframeCont = play.element;

          play.ready().then(() => {
            play.element.setAttribute('tabindex', '-1');
          });
        }

        if(!extraOptions.hasControls) this.$el.style.pointerEvents = 'none';

        if(extraOptions.resize) {
          this.resize();
          window.addEventListener('resize', () => this.resize());
        }
      });

      window.addEventListener('video-toggle', (e) => {
        if(e.detail === this.$el.id) {
          this.toggle();
        }
      });

      window.addEventListener('a11y-toggle', (e) => {
        if(e.detail) {
          this.pause(this.isPaused, this.isPlayingOrig);
        }
        else {
          if(this.isPlayingOrig || isInViewport(this.$el)) {
            this.play();
          }
        }
      });
    },

    play(isPaused = false) {
      this.$nextTick(() => {
        let reducedMotion = window.matchMedia("(prefers-reduced-motion: reduce)");
        if(reducedMotion.matches || this.a11yMode) return;

        let player = new Player(this.$el);
        this.isPlaying = this.isPlayingOrig = true;
        this.isPaused = isPaused;

        player.play().then(function() {
        }).catch(e => console.log(e));

        this.$dispatch('video-played', this.$el.id);
      });
    },

    pause(isPaused = true, isPlayingOrig = false) {
      this.$nextTick(() => {
        let player = new Player(this.$el);
        this.isPlaying = false;
        this.isPlayingOrig = isPlayingOrig;
        this.isPaused = isPaused;

        player.pause().then(function() {
        }).catch(e => console.log(e));

        this.$dispatch('video-paused', this.$el.id);
      });
    },

    toStart() {
      this.$nextTick(async () => {
        let player = new Player(this.$el);
        this.isPlaying = false;
        this.isPlayingOrig = false;
        this.isPaused = true;

        await player.pause();
        await player.setCurrentTime(0);

        this.$dispatch('video-paused', this.$el.id);
      });
    },

    toggle() {
      this.$nextTick(() => {
        let player = new Player(this.$el);
        if(this.isPlaying) {
          player.pause();
          this.isPlaying = this.isPlayingOrig = false;
          this.isPaused = true;
          this.$dispatch('video-paused', this.$el.id);
        } else {
          player.play();
          this.isPlaying = this.isPlayingOrig = true;
          this.isPaused = false;
          this.$dispatch('video-played', this.$el.id);
        }
      });
    }
  };
}


// Play/pause toggle

export function videoToggle(id, mobile) {
  return {
    isPlay: false,
    isPlayOrig: false,

    init() {

      this.$el.addEventListener('click', (e) => {
        this.$dispatch('video-toggle', id);
      });

      this.$el.addEventListener('mouseout', () => {
        this.$el.blur();
      });

      window.addEventListener('resize', () => {
        this.onresize();
      });

      window.addEventListener('video-played', (e) => {
        if(e.detail === id) {
          this.isPlay = this.isPlayOrig = true;
        }
      });

      window.addEventListener('video-paused', (e) => {
        if(e.detail === id) {
          this.isPlay = this.isPlayOrig = false;
        }
      });

      this.onresize();
    },

    onresize() {
      if(window.innerWidth <= 768) {
        this.$el.tabIndex = mobile ? "" : "-1";
      }
      else {
        this.$el.tabIndex = mobile ? "-1" : "";
      }
    }
  };
}
