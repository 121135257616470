/**
 * Usage:
 *
 * Step 1: Somewhere in JS...
 *   import Alpine from "alpinejs";
 *   import spine from "./observer";
 *   Alpine.data('observer', observer);
 *   Alpine.start();
 *
 * Step 2: Somewhere in markup...
 *   <div x-data="observer()" />
 *
 * @param selector
 * @returns {{init(): void, el: null, selector: null}}
 */
export default (opts = {}) => ({
  prevYPosition: 0,
  direction: null,
  heroOnly: opts.heroOnly,
  options: {
    rootMargin: null,
    threshold: 0
  },
  sections: [],
  observer: null,


  init() {
    this.options.rootMargin = `${this.$refs.header.offsetHeight * -1}px`;
    this.sections = document.querySelectorAll('[data-bars], [data-logo]');

    const getTargetSection = (entry) => {
      let index = 0;
      let target;
      for (let i = 0; i < this.sections.length; i++) {
        if (this.sections[i] === entry.target) {
          index = i;
        }
      }
      if (index >= this.sections.length - 1) {
        target = entry.target;
      } else {
        target = this.sections[index + 1];
      }

      return { target, index };
    };

    const updateColors = (target) => {
      let moreContrast = window.matchMedia("(prefers-contrast: more)");
      if(moreContrast.matches) return;

      const bars = target.getAttribute('data-bars');
      const logo = target.getAttribute('data-logo');
      this.$refs.header.setAttribute('data-bars-theme', bars);
      this.$refs.header.setAttribute('data-logo-theme', logo);
    };

    const shouldUpdate = (entry, index) => {
      if (this.direction === 'down' && !entry.isIntersecting) {
        return !(this.heroOnly && index > 1);
      }

      if (this.direction === 'up' && entry.isIntersecting) {
        return true;
      }

      return false;
    };

    const onIntersect = (entries) => {
      entries.forEach((entry) => {
        let target;
        let index;

        if (window.scrollY > this.prevYPosition) {
          this.direction = 'down';
        } else if(window.scrollY < this.prevYPosition) {
          this.direction = 'up';
        }

        this.prevYPosition = window.scrollY;

        if(this.direction === 'down') {
          let targetSection = getTargetSection(entry);
          target = targetSection.target;
          index = targetSection.index;
        }
        else {
          target = entry.target;
        }

        if (shouldUpdate(entry, index)) {
          updateColors(target);
        }
      });
    };

    this.observer = new IntersectionObserver(onIntersect, this.options);

    this.sections.forEach((section) => {
      this.observer.observe(section);
    });

    up.on('up:fragment:inserted', (event) => {
      this.init();
    });

    updateColors(this.sections[0]);
  },

});
