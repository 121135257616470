

/** Usage:
 *
 * Step 1: Somewhere in JS...
 *   import Alpine from "alpinejs";
 *   import swiper from "./swiper";
 *   Alpine.data('swiper', swiper);
 *   Alpine.start();
 *
 * Step 2: Somewhere in markup...
 *   <div x-data="...swiper({
            slidesPerView:1,
            })}" />
 *
 * @returns {{init(): void, el: null, selector: null}}
 */

// import any additional classes such as { Pagination }
import Swiper, { Autoplay, FreeMode } from 'swiper';
import 'swiper/css/bundle';

// initialize any additional classes such as { Pagination }
Swiper.use([Autoplay, FreeMode]);

export default (opts = {}, pauseOnLoad = false) => ({
  options: {
    ...opts
  },
  swiper: null,

  init() {
    if (this.swiper !== null) {
      return;
    }
    this.swiper = new Swiper(this.$el, this.options);

    if(pauseOnLoad) {
      this.swiper.autoplay.stop();
    }

    window.addEventListener('a11y-toggle', e => {
      if(e.detail) {
        this.swiper.autoplay.pause();
      }
      else {
        this.swiper.autoplay.start();
      }
    });
  },
});
