import axios from 'axios';

export function a11yToggle(showInfoMobile = false) {
  return {
    mouseover: false,
    active: false,

    init() {
      this.$el.onclick = async () => {
        this.active = true;

        if(window.innerWidth <= 768 && showInfoMobile) {
          this.a11yInfo = !this.a11yInfo;
        }
        else {
          this.a11yMode = !this.a11yMode;
          this.$dispatch('a11y-toggle', this.a11yMode);
        }
      };

      this.$el.onmouseover = () => { this.mouseover = true };
      this.$el.onmouseout = () => { this.mouseover = false; this.active = false };
    }
  };
}
